<template>
  <div class="text-right">
    <nk-button type="" is-icon-only-button class="btn-trigger" v-on:click="setModalOpenStatus(true)">
      <nio-icon icon="ni-edit"></nio-icon>
    </nk-button>
  </div>
  <nk-data-list :title="$t('Basic')">
    <n-k-data-list-item class="ccap" :label="$t('First name')" :value="userData.first_name"></n-k-data-list-item>
    <n-k-data-list-item class="ccap" :label="$t('Last name')" :value="userData.last_name"></n-k-data-list-item>
    <n-k-data-list-item :label="$t('Email')" :value="userData.email"></n-k-data-list-item>
  </nk-data-list>

  <nk-data-list :title="$t('PREFERENCES')">
    <n-k-data-list-item :value="activeLang === 'en' ? 'English' : 'Dutch' " label="Language">
      <div class="data-col data-col-end">
        <drop-down icon="ni-edit" title="" trigger-btn-classes="data-more" no-body-padding="yes">
          <template v-slot:body>
            <ul class="link-check">
            <li><span>{{ $t('Language') }}</span></li>
            <li :class="{active: activeLang === 'en'}"><a href="javascript:;" v-on:click="changeLang('en')">English</a></li>
            <li :class="{active: activeLang === 'nl'}"><a href="javascript:;" v-on:click="changeLang('nl')">Dutch</a></li>
            </ul>
          </template>
        </drop-down>
      </div>
    </n-k-data-list-item>
  </nk-data-list>

  <ion-modal
      :is-open="isModalOpenRef"
      @didDismiss="setModalOpenStatus(false)"
      :css-class="isDesktop ? 'modal-web' : ''"
  >
    <i-modal :title="$t('Update Profile')" @modal-close="setModalOpenStatus(false)">
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="f_name">{{ $t('First name') }}</label>
            <b-input type="text" size="lg" id="f_name" placeholder="John" v-model="userUpdatedData.first_name"/>
          </column>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="l_name">{{ $t('Last name') }}</label>
            <b-input type="text" size="lg" id="l_name" placeholder="Doe" v-model="userUpdatedData.last_name"/>
          </column>
        </row>
      </form-group>
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="email">{{ $t('Email') }}</label>
            <b-input type="email" size="lg" id="email" v-model="userUpdatedData.email" readonly=""/>
          </column>
        </row>
      </form-group>
      <template v-slot:footer class="p-5">
        <nk-button type="primary" class="mr-2" v-on:click="updateProfile(userUpdatedData)">{{ $t('Update Profile') }}</nk-button>
        <a href="javascript:;" class="link link-light" v-on:click="setModalOpenStatus(false)">{{ $t('Cancel') }}</a>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import {NkDataList, NKDataListItem} from "@core/layouts/data-list"
import store from "@/store"
import {IonModal, isPlatform, loadingController} from "@ionic/vue";
import IModal from "@core/components/ion-modal/IModal";
import {computed, reactive, ref, watch} from "vue";
import NkButton from "@core/components/button/NkButton";
import NioIcon from "@core/components/nio-icon/NioIcon";
import {FormGroup, Row, Column} from "@core/layouts";
import BInput from "@core/components/bp-form/components/BInput";

import DropDown from "@core/components/dropdown/DropDown";
import i18n from "@/libs/i18n"

import useUpdateProfile from "@/views/settings/useUpdateProfile";

export default {
  name: "ContentUserProfileSettings",
  components: {
    DropDown,
    BInput,
    Column,
    Row,
    FormGroup, NioIcon, NkButton, NkDataList, NKDataListItem, IonModal, IModal},
  setup(){

    const { updateProfile } = useUpdateProfile(store)
    const isDesktop = isPlatform('desktop')

    let userData = store.state.admin_data

    let isModalOpenRef = ref(false)
    watch(()=> store.state.settings.profileUpdatePopup, (n) => {
      isModalOpenRef.value = n
    })

    let userUpdatedData = computed(()=> reactive(Object.assign({}, userData)))

    const setModalOpenStatus = (s) => {
      store.dispatch('settings/open_profile_update_popup', s)
    }

    const changeLang = async (lang) => {

      const l = await loadingController.create({message: i18n.global.t('Processing...')})
      await l.present()
      store.dispatch('appConfig/change_language', lang).then(()=> i18n.global.locale = lang)
          .catch().then(()=>l.dismiss())
    }

    return {
      userData,
      isDesktop,
      isModalOpenRef,
      setModalOpenStatus,
      userUpdatedData,
      updateProfile,
      changeLang,
      activeLang: computed(()=> store.state.appConfig.lang),
    }
  },
}
</script>
